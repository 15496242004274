import React from "react"

import { withLogin } from "./withLogin"
import { LoginForm } from "./Form/LoginForm"
import { Layout } from "../Layout/Layout"
import { Social } from "../Social/Social"

export const Login = withLogin(
  ({ title, isInitialized, image }): JSX.Element => (
    <Layout title={title} image={image}>
      {isInitialized && <Social />}
      <LoginForm />
    </Layout>
  )
)
