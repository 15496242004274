import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCustomerLogin } from "../../../../hooks/useCustomer"

export const withLoginForm = Component => ({ name = "LoginForm" }) => {
  const { loginCustomer, data, setData, loading, errors } = useCustomerLogin()

  const { login } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_LOGIN {
      login: sanityPageAccountLogin {
        additionalEmailLabel
        additionalPasswordLabel
        additionalSubmitButton
        additionalPasswordErrorMessage
        additionalForgotPasswordText: _rawAdditionalForgotPasswordText(
          resolveReferences: { maxDepth: 6 }
        )
      }
    }
  `)

  const {
    additionalEmailLabel,
    additionalPasswordLabel,
    additionalSubmitButton,
    additionalPasswordErrorMessage,
    additionalForgotPasswordText,
  } = login || {}

  const handleSubmit = async event => {
    event.preventDefault()
    await loginCustomer({
      ...data,
    })
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  Component.displayName = name
  return (
    <Component
      data={data}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      errors={errors}
      additionalEmailLabel={additionalEmailLabel}
      additionalPasswordLabel={additionalPasswordLabel}
      additionalSubmitButton={additionalSubmitButton}
      additionalPasswordErrorMessage={additionalPasswordErrorMessage}
      additionalForgotPasswordText={additionalForgotPasswordText}
    />
  )
}
