import React from "react"

import { useImage } from "../../../hooks/useImage"
import { useFirebase } from "../../../hooks/useFirebase"

export const withLogin =
  Component =>
  ({ name = "Login", page }) => {
    const { getGatsbyImage } = useImage()

    const { isInitialized } = useFirebase()

    const { title, image: originalImage } = page || {}

    const image = getGatsbyImage(originalImage)

    Component.displayName = name
    return (
      <Component title={title} isInitialized={isInitialized} image={image} />
    )
  }
