import React from "react"

import { withLoginForm } from "./withLoginForm"
import { Submit, Errors, Error } from "../../Layout/LayoutStyles"
import { RichText } from "../../../RichText/RichText"
import { TextField } from "../../../TextField/TextField"

export const LoginForm = withLoginForm(
  ({
    data,
    handleChange,
    handleSubmit,
    loading,
    errors,
    additionalEmailLabel,
    additionalPasswordLabel,
    additionalSubmitButton,
    additionalPasswordErrorMessage,
    additionalForgotPasswordText,
  }): JSX.Element => (
    <form onSubmit={handleSubmit}>
      <TextField
        name="email"
        type="email"
        value={data?.email}
        onChange={handleChange}
        label={additionalEmailLabel}
        required
        spacing
      />
      <TextField
        name="password"
        type="password"
        value={data?.password}
        onChange={handleChange}
        label={additionalPasswordLabel}
        required
        spacing
      />
      {errors?.length > 0 && (
        <Errors>
          {errors?.map(({ code, message }) => (
            <Error key={code}>
              <p>
                {code === "UNIDENTIFIED_CUSTOMER"
                  ? additionalPasswordErrorMessage
                  : message}
              </p>
            </Error>
          ))}
        </Errors>
      )}
      <Submit size="primary" wide="true" type="submit" disabled={loading}>
        {additionalSubmitButton}
      </Submit>
      <RichText>{additionalForgotPasswordText}</RichText>
    </form>
  ),
)
